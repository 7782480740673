<template>
  <BreadCrumb ImageUrl="" PageName="AUMの沿革"/>
  <br/>
  <section class="history-one">
            <div class="container">
                <div class="block-title text-center">
                    <p class="color-2"><span>Our History</span></p>
                </div>
                <!-- /.block-title text-center -->
                <div class="history-one__row-wrap">
                    <div class="history-one__row-circle"></div>
                    <!-- /.history-one__row-circle -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInLeft" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInLeft;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-1.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>AUM株式会社設立</h3>
                                    <p>東京都葛飾区新小岩にAUM設立、資本金1,000万円、オフショア開発開始。
                                    </p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2018年03月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInRight" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInRight;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-2.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>ソリューション事業部を設立</h3>
                                    <p>オフショア開発開始</p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2018年04月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    <!-- /.row -->
                          <!-- /.row -->
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInRight" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInRight;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/resources/history-1-3.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>事務所移転</h3>
                                    <p>事務所は東京都東京都千代田区外神田に移転</p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2019年02月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="history-one__box wow fadeInRight" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInRight;" data-aos="fade-right" :data-aos-duration="500">
                                <div class="history-one__image">
                                    <img src="assets/images/pmark.jpg" alt="">
                                </div>
                                <!-- /.history-one__image -->
                                <div class="history-one__content">
                                    <h3>プライバシーマーク取得</h3>
                                    <p>一般財団法人日本情報経済社会推進協会から「プライバシーマーク」認証を取得いたしました。</p>
                                </div>
                                <!-- /.history-one__content -->
                            </div>
                            <!-- /.history-one__box -->
                        </div>
                        <!-- /.col-lg-6 -->
                        <div class="col-lg-6 d-flex">
                            <div class="my-auto">
                                <div class="history-one__date">
                                    <h3>2023年02月</h3>
                                </div>
                                <!-- /.history-one__date -->
                            </div>
                            <!-- /.my-auto -->
                        </div>
                        <!-- /.col-lg-6 d-flex -->
                    </div>
                    

                    <!-- /.row -->
                </div>
                <!-- /.history-one__row-wrap -->
            </div>
            <!-- /.container -->
        </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>

<style scoped>

</style>
