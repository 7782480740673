<template>
  <BreadCrumb ImageUrl="" PageName="社長挨拶"/>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">  
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
          <p class="color-1"><span>Greeting</span></p>
      </div>
      <div class="row post_content">
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">代表取締役社長</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">{{HomeInfo.Representative}}</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">皆様には平素より格別のご厚情を賜り有難く厚くお礼申し上げます。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
            経営資源として「ヒト、モノ、カネ」に「ＩＴ・情報」が加わり、ネットワーク通信環境の飛躍的な向上とソフトウエアの仮想化、スマートデバイスなどの普及により、企業活動と人々の暮らしは効率化、コスト削減を実現し、あらゆるものが所有するものから利用するものへと、そのあり方は大きく変化してきました。
        </p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
            これから益々　非連続な変化が続く激しい時代になることが予測されております。すなわち、グローバル競争の加速、国内市場の飽和感、業界構造の変化、お客様ニーズの多様化といった「ビジネス環境変化」、モバイル、クラウド、ビッグデータ、ＩOＴといった「テクノロジーの進化」により、新規事業創出、ビジネスモデル転換、新しいお客様価値創出、既存事業の差別化、異業種間連携の実現といった事業イノベーションが加速する時代が到来します。そして、あらゆる企業がビジネス環境の変化と、各種イノベーションに対し、ＩＴ展開の俊敏性、柔軟性を担保するため、ＩＴインフラ、アプリケーションおよびファイルデータ基盤をプラットフォーム化する必要があります。
        </p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">
            {{HomeInfo.CompanyName}}は、クレジットカードビジネス、流通小売業を中心とする多くのお客様のＩＴシステム構築、運用に携わると共に、お客様ビジネス基盤、社会基盤をＩＴシステムの側面から支援し続けてまいりました。これからも、長年培った技術力、専門スキル、業務ノウハウと最新テクノロジーを活かし、各事業において時代環境変化に適応した製品・サービスの創出、安定供給を通じて、お客様事業の維持、成長、発展に貢献し続けるため邁進してまいります。
        </p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">私達は最先端の技術と知識を以って、目覚しく進化する情報化社会に適切に対応し、お客様の事業の発展に貢献して参りたいと考えています。 未来は漫然と待っているものではなく、切り開いて行くものであります。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">弊社は「人材・信頼・共生・効率・創造」を礎に、未来に向けて優れたソリューションを持ち、コンサルティングから運用・アウトソーシングまで、一流のサービスを提供する国際的情報サービスグループ企業を目指して参ります。</p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
        <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">今後とも、皆様方にはご理解、ご支援、ご鞭撻のほど、心よりお願い申し上げます。</p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "News",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>

<style scoped>

</style>
