<template>
<footer class="site-footer">
    <div class="particles-snow" id="footer-snow"></div>
    <!-- /#footer-snow.particles-snow -->

    <img src="/assets/images/shapes/footer-shape-1-1.png" class="site-footer__bg-shape-1" alt="">
    <img src="/assets/images/shapes/footer-shape-1-2.png" class="site-footer__bg-shape-2" alt="">
    <img src="/assets/images/shapes/footer-shape-1-3.png" class="site-footer__bg-shape-3" alt="">
    <img src="/assets/images/shapes/footer-shape-1-4.png" class="site-footer__bg-shape-4" alt="">
    <div class="site-footer__upper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 sm_no_show" data-aos="fade-right" :data-aos-duration="500">
                    <div class="footer-widget footer-widget__about">
                        <h3 class="footer-widget__title">AUM株式会社</h3>
                        <p>
                          先進のICT技術で常に価値あるサービスを提供し、お客様と共に新しい未来を創ります。<br/>
                          Create your precious future.
                        </p>
                        <div class="footer-widget__social">
                            <a ><i class="fab fa-facebook-f"></i></a>
                            <a ><i class="fab fa-twitter"></i></a>
                            <a><i class="fab fa-google-plus-g"></i></a>
                            <a><i class="fab fa-linkedin-in"></i></a>
                        </div>
                        <!-- /.footer-widget__social -->
                    </div>
                    <!-- /.footer-widget footer-widget__about -->
                </div>
                <!-- /.col-lg-3 col-md-6 col-sm-12 -->
                <div class="col-lg-3 col-md-6 col-sm-12 sm_no_show">
                    <div class="footer-widget footer-widget__links__1" data-aos="fade-right" :data-aos-duration="500">
                        <h3 class="footer-widget__title">サービス</h3>
                        <ul class="list-unstyled footer-widget__links-list">
                            <li><router-link to="/serviceDetail/CRM">CRMシステム</router-link></li>
                            <li><router-link to="/serviceDetail/AI">人工知能</router-link></li>
                            <li><router-link to="/serviceDetail/Development">システム開発</router-link></li>
                            <li><router-link to="/serviceDetail/BigData">ビッグデータ</router-link></li>
                            <li><router-link to="/serviceDetail/Embedded">組込開発</router-link></li>
                        </ul>
                        <!-- /.list-unstyled footer-widget__links-list -->
                    </div>
                    <!-- /.footer-widget -->
                </div>
                <!-- /.col-lg-3 col-md-6 col-sm-12 -->
                <div class="col-lg-3 col-md-6 col-sm-12" data-aos="fade-right" :data-aos-duration="500">
                    <div class="footer-widget footer-widget__links__2">
                        <h3 class="footer-widget__title">会社情報</h3>
                        <ul class="list-unstyled footer-widget__links-list">
                            <li><router-link to="/about">会社概要</router-link></li>
                            <li><router-link to="/service">事業内容</router-link></li>
                            <li><router-link to="/greeting">社長挨拶</router-link></li>
                            <li><router-link to="/philosophy">経営理念</router-link></li>
                            <li><router-link to="/history">会社沿革</router-link></li>
                            <li><router-link to="/strength">AUMの強み</router-link></li>
                        </ul>
                        <!-- /.list-unstyled footer-widget__links-list -->
                    </div>
                    <!-- /.footer-widget -->
                </div>
                <!-- /.col-lg-3 col-md-6 col-sm-12 -->
                <div class="col-lg-3 col-md-6 col-sm-12" data-aos="fade-right" :data-aos-duration="500">
                    <div class="footer-widget footer-widget__contact">
                        <h3 class="footer-widget__title">お問い合わせ</h3>
                        <p><i class="fa fa-building"></i>〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}</p>
                        <p><i class="fa fa-envelope-open"></i><a :href="'mailto:'+HomeInfo.Email" style="line-height:40px">{{HomeInfo.Email}}</a></p>
                        <p><i class="fa fa-phone"></i>
                           <a :href="'tel:'+HomeInfo.Tel" style="line-height:40px">{{HomeInfo.Tel}}</a>
                        </p>
                    </div>
                    <!-- /.footer-widget -->
                </div>
                <!-- /.col-lg-3 col-md-6 col-sm-12 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->

    </div>
    <!-- /.site-footer__upper -->
    <div class="site-footer__bottom" data-aos="fade-right" :data-aos-duration="500">
        <div class="container">
            <p>AUM Co., Ltd. Copyright © {{(new Date().getFullYear())}} All Rights Reserved.</p>
            <router-link to="/"><img src="/assets/images/logo-1-1.png" alt="" style="height:16px"></router-link>
            <ul class="list-unstyled site-footer__bottom-menu" >
                <li style="font-size:14px"><router-link to="/policy" style="font-size:14px">プライバシーポリシー</router-link></li>
                <!-- <li><a href="/fqa">よくあるご質問</a></li> -->
            </ul>
            <!-- /.list-unstyled site-footer__bottom-menu -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.site-footer__bottom -->

</footer>
<a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>

</template>
<script>
import {ref,onMounted,onUnmounted} from "vue";
import { useRoute} from "vue-router"
export default {
  name: "Footer",
  setup(){
    const initSlick = () => {
         if ($('.curved-circle').length) {
              $('.curved-circle').circleType({
                  position: 'absolute',
                  dir: .95,
                  radius: 85,
                  forceHeight: false,
                  forceWidth: true
              });
          }
          //Submenu Dropdown Toggle
          if ($('.main-nav__main-navigation li.dropdown ul').length) {
              $('.main-nav__main-navigation li.dropdown').append('<button class="dropdown-btn"><i class="fa fa-angle-right"></i></button>');
          }

          function dynamicCurrentMenuClass(selector) {
              let FileName = window.location.href.split('/').reverse()[0];

              selector.find('li').each(function() {
                  let anchor = $(this).find('a');
                  if ($(anchor).attr('href') == FileName) {
                      $(this).addClass('current');
                  }
              });
              // if any li has .current elmnt add class
              selector.children('li').each(function() {
                  if ($(this).find('.current').length) {
                      $(this).addClass('current');
                  }
              });
              // if no file name return 
              if ('' == FileName) {
                  selector.find('li').eq(0).addClass('current');
              }
          }
                
          // mobile menu
          if ($('.main-nav__main-navigation').length) {
              let mobileNavContainer = $('.mobile-nav__container');
              let mainNavContent ='<a href="/" class="side-menu__logo" style="position: absolute;margin-top: -44px;z-index: 9999;padding-left: 20px;"><img src="/assets/images/logo-1-1.png" alt=""></a>' + $('.main-nav__main-navigation').html();
              mobileNavContainer.append(function() {
                  return mainNavContent;
              });
              //Dropdown Button
              mobileNavContainer.find('li.dropdown .dropdown-btn').on('click', function() {
                  $(this).toggleClass('open');
                  $(this).prev('ul').slideToggle(500);
              });

              // dynamic current class        
              let mainNavUL = $('.main-nav__main-navigation').find('.main-nav__navigation-box');
              let mobileNavUL =  mobileNavContainer.find('.main-nav__navigation-box');
              dynamicCurrentMenuClass(mainNavUL);
              dynamicCurrentMenuClass(mobileNavUL);
          }
          if ($('.mc-form').length) {
              var mcURL = $('.mc-form').data('url');
              $('.mc-form').ajaxChimp({
                  url: mcURL,
                  callback: function(resp) {
                      // appending response
                      $('.mc-form__response').append(function() {
                              return '<p class="mc-message">' + resp.msg + '</p>';
                      })
                      // making things based on response
                      if (resp.result === 'success') {
                          // Do stuff
                          $('.mc-form').removeClass('errored').addClass('successed');
                          $('.mc-form__response').removeClass('errored').addClass('successed');
                          $('.mc-form').find('input').val('');
                          $('.mc-form__response p').fadeOut(10000);
                      }
                      if (resp.result === 'error') {
                          $('.mc-form').removeClass('successed').addClass('errored');
                          $('.mc-form__response').removeClass('successed').addClass('errored');
                          $('.mc-form').find('input').val('');
                          $('.mc-form__response p').fadeOut(10000);
                      }
                  }
              });
          }

          if ($('.datepicker').length) {
              $('.datepicker').datepicker();
          }


          if ($('.plan-visit__tab-links').length) {
              var planVisitLink = $('.plan-visit__tab-links').find('.nav-link');
              planVisitLink.on('click', function(e) {
                  var target = $(this).attr('data-target');
                  // animate
                  $('html, body').animate({
                      scrollTop: $(target).offset().top - 50
                  }, 1000);


                  planVisitLink.removeClass('active');
                  $(this).addClass('active');

                  return false;
              })
          }

          if ($('.contact-form-validated').length) {
              $('.contact-form-validated').validate({ // initialize the plugin
                  rules: {
                      fname: {
                          required: true
                      },
                      lname: {
                          required: true
                      },
                      name: {
                          required: true
                      },
                      email: {
                          required: true,
                          email: true
                      },
                      service: {
                          required: true
                      },
                      message: {
                          required: true
                      },
                      subject: {
                          required: true
                      }
                  },
                  submitHandler: function(form) {
                      // sending value with ajax request
                      $.post($(form).attr('action'), $(form).serialize(), function(response) {
                          $(form).parent().find('.result').append(response);
                          $(form).find('input[type="text"]').val('');
                          $(form).find('input[type="email"]').val('');
                          $(form).find('textarea').val('');
                      });
                      return false;
                  }
              });
          }
          if ($('.counter').length) {
              $('.counter').counterUp({
                  delay: 10,
                  time: 1500
              });
          }
          if ($('.img-popup').length) {
              var groups = {};
              $('.img-popup').each(function() {
                  var id = parseInt($(this).attr('data-group'), 10);

                  if (!groups[id]) {
                      groups[id] = [];
                  }

                  groups[id].push(this);
              });


              $.each(groups, function() {

                  $(this).magnificPopup({
                      type: 'image',
                      closeOnContentClick: true,
                      closeBtnInside: false,
                      gallery: {
                          enabled: true
                      }
                  });

              });

          };
          if ($('.wow').length) {
              var wow = new WOW({
                  boxClass: 'wow', // animated element css class (default is wow)
                  animateClass: 'animated', // animation css class (default is animated)

                  mobile: true, // trigger animations on mobile devices (default is true)
                  live: true // act on asynchronously loaded content (default is true)
              });
              wow.init();
          }

          if ($('.video-popup').length) {
              $('.video-popup').magnificPopup({
                  disableOn: 700,
                  type: 'iframe',
                  mainClass: 'mfp-fade',
                  removalDelay: 160,
                  preloader: true,

                  fixedContentPos: false
              });
          }
          if ($('[data-toggle="tooltip"]').length) {
              $('[data-toggle="tooltip"]').tooltip();
          }
          if ($('.stricky').length) {
              $('.stricky').addClass('original').clone(true).insertAfter('.stricky').addClass('stricked-menu').removeClass('original');
          }
          if ($('.scroll-to-target').length) {
              $(".scroll-to-target").on('click', function() {
                  var target = $(this).attr('data-target');
                  // animate
                  $('html, body').animate({
                      scrollTop: $(target).offset().top
                  }, 1000);

                  return false;

              });
          }

          //if ($('.side-menu__toggler').length) {
          $('.side-menu__toggler').on('click', function(e) {
              $('.side-menu__block').toggleClass('active');
              e.preventDefault();
          });
          // }

          if ($('.side-menu__block-overlay').length) {
              $('.side-menu__block-overlay').on('click', function(e) {
                  $('.side-menu__block').removeClass('active');
                  e.preventDefault();
              });
          }

          if ($('.side-content__toggler').length) {
              $('.side-content__toggler').on('click', function(e) {
                  $('.side-content__block').toggleClass('active');
                  e.preventDefault();
              });
          }

          if ($('.side-content__block-overlay').length) {
              $('.side-content__block-overlay').on('click', function(e) {
                  $('.side-content__block').removeClass('active');
                  e.preventDefault();
              });
          }


          if ($('.search-popup__toggler').length) {
              $('.search-popup__toggler').on('click', function(e) {
                  $('.search-popup').addClass('active');
                  e.preventDefault();
              });
          }

          if ($('.search-popup__overlay').length) {
              $('.search-popup__overlay').on('click', function(e) {
                  $('.search-popup').removeClass('active');
                  e.preventDefault();
              });
          }
          $(window).on('scroll', function() {
              if ($('.scroll-to-top').length) {
                  var strickyScrollPos = 100;
                  if ($(window).scrollTop() > strickyScrollPos) {
                      $('.scroll-to-top').fadeIn(500);
                  } else if ($(this).scrollTop() <= strickyScrollPos) {
                      $('.scroll-to-top').fadeOut(500);
                  }
              }
              if ($('.stricked-menu').length) {
                  var headerScrollPos = 100;
                  var stricky = $('.stricked-menu');
                  if ($(window).scrollTop() > headerScrollPos + 300) {
                      //stricky.addClass('stricky-fixed');
                      $('#original-fix').css('background-image','linear-gradient(90deg, rgb(26 80 155) 0%, rgb(11 59 146) 51%, #409eff 100%)')
                  } else if ($(this).scrollTop() <= headerScrollPos) {
                      // stricky.removeClass('stricky-fixed');
                       $('#original-fix').css('background-image','')
                  }
              }
          });
          if ($('.accrodion-grp').length) {
              var accrodionGrp = $('.accrodion-grp');
              accrodionGrp.each(function() {
                  var accrodionName = $(this).data('grp-name');
                  var Self = $(this);
                  var accordion = Self.find('.accrodion');
                  Self.addClass(accrodionName);
                  Self.find('.accrodion .accrodion-content').hide();
                  Self.find('.accrodion.active').find('.accrodion-content').show();
                  accordion.each(function() {
                      $(this).find('.accrodion-title').on('click', function() {
                          if ($(this).parent().hasClass('active') === false) {
                              $('.accrodion-grp.' + accrodionName).find('.accrodion').removeClass('active');
                              $('.accrodion-grp.' + accrodionName).find('.accrodion').find('.accrodion-content').slideUp();
                              $(this).parent().addClass('active');
                              $(this).parent().find('.accrodion-content').slideDown();
                          };


                      });
                  });
              });

          };

          if ($('.thm__owl-carousel').length) {
              $('.thm__owl-carousel').each(function() {

                  var Self = $(this);
                  var carouselOptions = Self.data('options');
                  var carouselPrevSelector = Self.data('carousel-prev-btn');
                  var carouselNextSelector = Self.data('carousel-next-btn');
                  var carouselDotsContainer = Self.data('carousel-dots-container');
                  var thmCarousel = Self.owlCarousel(carouselOptions);
                  if (carouselPrevSelector !== undefined) {
                      $(carouselPrevSelector).on('click', function() {
                          thmCarousel.trigger('prev.owl.carousel', [1000]);
                          return false;
                      });
                  }
                  if (carouselNextSelector !== undefined) {
                      $(carouselNextSelector).on('click', function() {
                          thmCarousel.trigger('next.owl.carousel', [1000]);
                          return false;
                      });
                  }
                  if (carouselDotsContainer !== undefined) {
                      $(carouselDotsContainer).find('.owl-dot').on('click', function() {
                          var dotIndex = $(this).index();
                          // $(carouselDotsContainer).find('.owl-dot').removeClass('active');
                          // $(carouselDotsContainer).find('.owl-dot').eq(dotIndex).addClass('active');
                          thmCarousel.trigger('to.owl.carousel', [dotIndex, 1000]);
                      });
                  }
              });
          }

          $(window).on('load', function() {
              if ($('.preloader').length) {
                  $('.preloader').fadeOut('slow');
              }

              if ($('.side-menu__block-inner').length) {
                  $('.side-menu__block-inner').mCustomScrollbar({
                      axis: 'y',
                      theme: 'dark'
                  });
              }


              if ($('.side-content__block-inner').length) {
                  $('.side-content__block-inner').mCustomScrollbar({
                      axis: 'y',
                      theme: 'dark'
                  });
              }

              if ($('.custom-cursor__overlay').length) {

                  // / cursor /
                  var cursor = $(".custom-cursor__overlay .cursor"),
                      follower = $(".custom-cursor__overlay .cursor-follower");

                  var posX = 0,
                      posY = 0;

                  var mouseX = 0,
                      mouseY = 0;

                  TweenMax.to({}, 0.016, {
                      repeat: -1,
                      onRepeat: function() {
                          posX += (mouseX - posX) / 9;
                          posY += (mouseY - posY) / 9;

                          TweenMax.set(follower, {
                              css: {
                                  left: posX - 22,
                                  top: posY - 22
                              }
                          });

                          TweenMax.set(cursor, {
                              css: {
                                  left: mouseX,
                                  top: mouseY
                              }
                          });

                      }
                  });

                  $(document).on("mousemove", function(e) {
                      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                      mouseX = e.pageX;
                      mouseY = e.pageY - scrollTop;
                  });
                  $("button, a").on("mouseenter", function() {
                      cursor.addClass("active");
                      follower.addClass("active");
                  });
                  $("button, a").on("mouseleave", function() {
                      cursor.removeClass("active");
                      follower.removeClass("active");
                  });
                  $(".custom-cursor__overlay").on("mouseenter", function() {
                      cursor.addClass("close-cursor");
                      follower.addClass("close-cursor");
                  });
                  $(".custom-cursor__overlay").on("mouseleave", function() {
                      cursor.removeClass("close-cursor");
                      follower.removeClass("close-cursor");
                  });
              }
              if ($('.masonary-layout').length) {
                  $('.masonary-layout').isotope({
                      layoutMode: 'masonry',
                      itemSelector: '.masonary-item'
                  });
              }

              if ($('.post-filter').length) {
                  var postFilterList = $('.post-filter li');
                  // for first init
                  $('.filter-layout').isotope({
                      filter: '.filter-item',
                      animationOptions: {
                          duration: 500,
                          easing: 'linear',
                          queue: false
                      }
                  });
                  // on click filter links
                  postFilterList.children('span').on('click', function() {
                      var Self = $(this);
                      var selector = Self.parent().attr('data-filter');
                      postFilterList.children('span').parent().removeClass('active');
                      Self.parent().addClass('active');


                      $('.filter-layout').isotope({
                          filter: selector,
                          animationOptions: {
                              duration: 500,
                              easing: 'linear',
                              queue: false
                          }
                      });
                      return false;
                  });
              }
              if ($('.post-filter.has-dynamic-filter-counter').length) {
                  // var allItem = $('.single-filter-item').length;

                  var activeFilterItem = $('.post-filter.has-dynamic-filter-counter').find('li');

                  activeFilterItem.each(function() {
                      var filterElement = $(this).data('filter');
                      var count = $('.gallery-content').find(filterElement).length;
                      $(this).children('span').append('<span class="count"><b>' + count + '</b></span>');
                  });
              }
              if ($('.testimonials-two__thumb-carousel').length) {
                  var testiTwoThumbCarousel = new Swiper('.testimonials-two__thumb-carousel', {
                      slidesPerView: 3,
                      spaceBetween: 20,
                      freeMode: true,
                      speed: 1400,
                      watchSlidesVisibility: true,
                      watchSlidesProgress: true,
                      loop: true,
                      autoplay: {
                          delay: 5000
                      },
                  });
              }
              if ($('.testimonials-two__carousel').length) {
                  var testiTwoCarousel = new Swiper('.testimonials-two__carousel', {
                      navigation: {
                          nextEl: '',
                          prevEl: '',
                      },
                      observer: true,
                      observeParents: true,
                      speed: 1400,
                      mousewheel: true,
                      autoplay: {
                          delay: 5000
                      },
                      thumbs: {
                          swiper: testiTwoThumbCarousel
                      }
                  });
              }
          });
    };
    
    const showItems = ref(true)
    function hidden() {
      if (window.innerWidth < 767) {
        showItems.value = false
      }else {
        showItems.value = true
      }
    }
    onMounted(() => {
      initSlick();
      hidden();
    })
    window.addEventListener('resize', hidden)
    onUnmounted(() => {
      window.removeEventListener('resize', hidden)
    })
    
    return{showItems}
  },
}
</script>

<style scoped>

</style>
