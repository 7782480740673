<template>
  <!-- Start reinfo_breadcrumb section -->
  <section class="page-header" :style="SectionImageUrl">
    <div class="particles-snow" id="header-snow"></div>
    <!-- /#header-snow.particles-snow -->
    <img src="/assets/images/shapes/page-header-shape-1-1.png" class="page-header__bg-shape-1" alt="">
    <img src="/assets/images/shapes/page-header-shape-1-2.png" class="page-header__bg-shape-2" alt="">
    <img src="/assets/images/shapes/footer-shape-1-1.png" class="page-header__bg-shape-3" alt="">
    <img src="/assets/images/shapes/footer-shape-1-3.png" class="page-header__bg-shape-4" alt="">
    <div class="container text-center">
        <h2 data-aos="fade-right" :data-aos-duration="500">{{ PageName }}</h2>
          <ul class="list-unstyled thm-breadcrumb">
            <li :data-aos-duration="500" data-aos="fade-right">
              <i aria-hidden="true" class="fa fa-home fa-1"></i>
              <router-link to="/">ホーム</router-link>
            </li>
            <li data-aos="fade-right" :data-aos-duration="500">
              <template v-if="SubName!==''">
                <router-link :to="PageLink" @click="$root.reload">{{ PageName }}</router-link>
              </template>
              <template v-else>
                {{ PageName }}
              </template>
            </li>
            <li data-aos="fade-right" :data-aos-duration="500" v-if="SubName!==''">{{ SubName }}</li>
          </ul>
    </div>
    <!-- /.container text-center -->
  </section>
   <div class="banner-hidden">
      <div class="banner-background-01"></div>
      <div class="banner-background-02"></div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
     PageName: {
       type: String,
       default:''
     },
     PageLink: {
       type: String,
       default:''
     },
     ImageUrl: {
       type: String,
       default:''
     },
     SubName: {
       type: String,
       default:''
     }
  },
  computed:{
      SectionImageUrl:function() {
          return this.ImageUrl ? 'background-image: url(' + this.ImageUrl +');': '';
      }
   }
}
</script>
