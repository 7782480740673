<template>
  <Header />
  <router-view v-if="isRouterAlive"/>
  <Footer />
</template>

<style></style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {nextTick, onMounted, ref} from "vue";
import { provide } from 'vue'

export default {
  components: { Footer, Header},

  setup() {
    const isRouterAlive = ref( true)
    function reload(){
      isRouterAlive.value = false
      nextTick( function (){
        isRouterAlive.value = true
      })
    }
    provide('reload', reload)
    onMounted(() => {
      new WOW().init();
    })
    return{reload,isRouterAlive}
  },
  watch:{
    '$route':function(to,from){
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
};
</script>
