<template>
  <!-- Start aum breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-1.png" PageName="事業内容"/>
  <!-- End aum breadcrumb section -->

 <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px;margin-bottom:50px">
    <div class="container">
       <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>Business</span></p>
        </div>
        <section class="comment-one__single">
          <div class="comment-one__image">
              <img src="assets/images/blog/blog-1-8.jpg" alt="">
          </div>
          <!-- /.comment-one__image -->
          <div class="comment-one__content" data-aos="fade-right" :data-aos-duration="500">
              <h3>オフショア開発及び中国進出支援サービス</h3>
              <p>日本のお客様のご要望を基づくオフショア開発。 日本のお客様のご要望によるラボ契約での運用監視サービス。 中国進出の日本企業向けのシステム開発・保守サービス。 中国マーケットを求める日本企業の進出支援の役割。</p>
          </div>
          <!-- /.comment-one__content -->
      </section>
      <section class="comment-one__single">
          <div class="comment-one__image" data-aos="fade-right" :data-aos-duration="500">
              <img src="assets/images/blog/blog-1-12.jpg" alt="">
          </div>
          <!-- /.comment-one__image -->
          <div class="comment-one__content" data-aos="fade-right" :data-aos-duration="500">
              <h3>ソフトウエアの開発・保守サービス</h3>
              <p>AUMが開発するシステムは、すべて日本国内で設計・製造・納入され、部品購入リストは関連規則や基準を満たすよう 審査しています。</p>
          <!-- /.comment-one__content -->
          </div>
      </section>
      <section class="comment-one__single">
          <div class="comment-one__image" data-aos="fade-right" :data-aos-duration="500">
              <img src="assets/images/blog/blog-1-11.jpg" alt="">
          </div>
          <!-- /.comment-one__image -->
          <div class="comment-one__content" data-aos="fade-right" :data-aos-duration="500">
              <h3>ソフトウエアの開発・保守サービス</h3>
              <p>お客様のご要望に応じた「OS、DB、言語、ツール、フレームワーク」によるソフトウエア開発・保守サービス。汎用コンピュータの開発、コンバージョン、マイグレーションサービス。 タブレット端末、携帯電話、家電、カーナビの組込みソフトの開発サービス。</p>
          </div>
          <!-- /.comment-one__content -->
      </section>
      <section class="comment-one__single">
          <div class="comment-one__image" data-aos="fade-right" :data-aos-duration="500">
              <img src="assets/images/blog/blog-1-9.jpg" alt="">
          </div>
          <!-- /.comment-one__image -->
          <div class="comment-one__content" data-aos="fade-right" :data-aos-duration="500">
              <h3>教育・ラボサービス</h3>
              <p>日本のお客様のご要望に応じた技術及び中国語教育サービス。 日本のお客様のご要望によるラボ契約での運用監視サービス。</p>
          </div>
          <!-- /.comment-one__content -->
      </section>
    </div>
 </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Business",
  components:{ BreadCrumb },
  setup() {}
}
</script>

<style scoped>

</style>
