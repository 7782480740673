import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '03-6874-5334',
    Fax: '',
    PostCode: '101-0021',
    Email: 'info@aum-info.co.jp',
    Address: '東京都千代田区外神田2丁目2-17',
    Building: '共同ビル(万世)4F 47',
    CompanyName: 'AUM株式会社',
    CompanyNo: '（略称：AUM）',
    Representative: '周　鵬',
    Katakana: 'シュウ　ホウ',
    Capital: '1000',
    Banks: '三菱UFJ銀行、住信SBIネット銀行',
    CreateDate: '2018年03月01日',
    PMarkNo: '第１７００４５３８（０１）号',
    //YearSell: '1.9億円 （2021年3月期）',
    HakenNo: '', // '派13-○○○○○○'
    //CTO: '取締役　姜 再歓',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.080415471182!2d139.76542995163277!3d35.69963868009285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d09f310e0a7%3A0xc84b3c5c524b8a61!2zQVVN5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1651154752493!5m2!1sja!2sjp',
}
const app = createApp(App)

app.config.globalProperties.HomeInfo = HomeInfo;
app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')