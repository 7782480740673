<template>
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-6.png" PageName="AUMの強み"/>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">  
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
          <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500" class="color-1"><span>Strength</span></p>
      </div>
      <div class="row post_content">
            <h2>１．日中のグループ一体化</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">日本法人のAUMを中心としてグループ全体が一体化しています。日本AUMの代表取締役が中国AUMの代表取締役を兼務し、経営・管理に参加しています。日中で「一元管理、開発基準統一、リソースの共有、グループ内人材シフト」を実施しています。</p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>２．精鋭による上流からの一連のサービス提供</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">上級SEの豊富さと開発一貫サービスまたSEは精鋭が揃い、動員力があります。日本・中国合わせて150名以上の上級SE（ブリッジSE）がおり、幅広い業務や大規模システムのオフショア開発を実現しています。豊富なSEの活躍とAUMグループとして日中間のスムースな連携により「基本設計、詳細設計、製造、単体テスト、結合テスト、保守」にいたる一連のサービス提供が可能となります。</p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>3．「高品質・短納期・低コスト」開発</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">得意業務分野や開発技術を持ち、確固とした品質管理体制の基に高品質、高生産性を保っています。2015年から、100人月を超えるプロジェクトを既に70件、一括受託し、その全てを成功させることが出来ました。この中には200人月前後のプロジェクトも5件含まれています。そして、オフショア開発プロジェクトが約150件あります。業務研修会、長期間に亘るプロジェクト・マネジメント研修会、プロジェクトマネジメント等各種資格支援制度等により培った、プロジェクト管理や品質管理の豊富なノウハウをベースに「高品質・短納期・低コスト」開発を実現しています。</p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>4．品質管理体制</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">万全なる品質管理を行い、受注した全てのプロジェクトを開発作業フロー（標準的な作業の流れと工程別規約）に基づいて開発を行っています。開発部門と品質管理部門の間では十分なコミュニケーションが図られ、品質の第３者チェックやＰＭＯ参加による見積、進捗、品質の審査会を行っています。</p>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"><br></p>
            <h2>5．セキュリティ体制</h2>
            <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">万全なるセキュリティ体制を確立しています。個人情報、顧客情報保護の観点から組織・体制面の安全管理、教育・誓約等の人的、物理的、技術的な安全管理及び日中間にまたがる安全管理の徹底を図っています。</p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Strength",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>
