<template>
  <!-- Start aum breadcrumb section -->
  <BreadCrumb ImageUrl="/assets/images/shapes/header-bg-3.png" PageName="会社概要"/>
  <!-- End aum breadcrumb section -->
  <section class="service-one">
    <div class="container">
        <div class="block-title text-center">
            <p><span>About AUM</span></p>
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-list-alt"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>会社名</h4>
                <p>{{HomeInfo.CompanyName}}{{HomeInfo.CompanyNo}}</p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-building"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>住所</h4>
                <p>
                  〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-phone" style="background-image: linear-gradient(-45deg, rgb(64, 158, 255) 0%, rgb(255 64 197) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>代表電話番号</h4>
                <p>
                  <span v-if="HomeInfo.Fax" >TEL. </span><a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                  <span v-if="HomeInfo.Fax" >&emsp;FAX. {{HomeInfo.Fax}}</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-envelope-open" style="background-image: linear-gradient(-45deg, #409eff 0%, rgb(235, 255, 64) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>E-mail</h4>
                <p>
                  <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-user-circle"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>代表者名 (CEO)</h4>
                <p>
                  {{HomeInfo.Representative}}<span v-if="HomeInfo.Katakana">（{{HomeInfo.Katakana}}）</span>
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.CTO!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-users" style="background-image: linear-gradient(-45deg, #88b0ff 0%, #2098d1 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px">
                <h4>役員</h4>
                <p>
                  {{HomeInfo.CTO}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-jpy" style="background-image: linear-gradient(-45deg, rgb(220, 136, 255) 0%, rgb(253 255 53) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>資本金</h4>
                <p>
                  {{formatPrice(this.HomeInfo.Capital)}}万円
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-calendar-check-o" style="background-image: linear-gradient(-45deg, rgb(220, 136, 255) 0%, #409eff 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>設立</h4>
                <p>
                  {{HomeInfo.CreateDate}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-university" style="background-image: linear-gradient(-45deg, rgb(255 186 136) 0%, rgb(235 255 64) 100%)"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>主要取引銀行</h4>
                <p>
                  {{HomeInfo.Banks}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.YearSell!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-line-chart" style="background-image: linear-gradient(-45deg, rgb(236 16 34) 0%, rgb(235, 255, 64) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>売上</h4>
                <p>
                  {{HomeInfo.YearSell}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
        <div class="contact-two__info-single" v-if="HomeInfo.HakenNo!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-gift" style="background-image: linear-gradient(-45deg, rgb(236 16 34) 0%, rgb(235, 255, 64) 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>労働者派遣事業許可番号</h4>
                <p>
                  {{HomeInfo.HakenNo}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>
         <div class="contact-two__info-single" v-if="HomeInfo.PMarkNo!=''">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-product-hunt" style="background-image: linear-gradient(-45deg, #409eff 0%, #67c23a 100%);"></i>
            </div>
            <!-- /.contact-two__info-icon -->
            <div class="contact-two__info-content" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>プライバシーマーク登録番号</h4>
                <p>
                  {{HomeInfo.PMarkNo}}
                </p>
            </div>
            <!-- /.contact-two__info-content -->
        </div>

    </div>
    <!-- /.container -->
</section>

<!-- /.page-header -->
<section class="about-one">
    <img src="/assets/images/shapes/bg-shape-1-1.png" class="error-404__bg-shape-1" alt="">
    <img src="/assets/images/shapes/bg-shape-1-2.png" class="error-404__bg-shape-2" alt="">
    <img src="/assets/images/shapes/bg-shape-1-3.png" class="error-404__bg-shape-3" alt="">

    <div class="error-404__bubble-1"></div>
    <!-- /.error-404__bubble-1 -->
    <div class="error-404__bubble-2"></div>
    <!-- /.error-404__bubble-2 -->
    <div class="error-404__bubble-3"></div>
    <!-- /.error-404__bubble-3 -->
    <div class="error-404__bubble-4"></div>
    <!-- /.error-404__bubble-4 -->
    <div class="error-404__bubble-5"></div>
    <!-- /.error-404__bubble-5 -->
    <div class="error-404__bubble-6"></div>
    <!-- /.error-404__bubble-6 -->
    <div class="error-404__bubble-7"></div>
    <!-- /.error-404__bubble-7 -->
    <div class="error-404__bubble-8"></div>
    <!-- /.error-404__bubble-8 -->
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-one__content">
                    <div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
                        <p class="color-2"><span>Business</span></p>
                        <h3>事業内容</h3>
                    </div>
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box" data-aos="fade-right" :data-aos-duration="500">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content">
                                <h3>国内におけるソフトウェア受託、web応用システムの設計開発等</h3>
                                <p>
                                  管理情報、通信応用システムの設計開発等<br/>
                                  電子取引サイトの設計開発等
                                </p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                        <div class="about-one__box" data-aos="fade-right" :data-aos-duration="500">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content" data-aos="fade-right" :data-aos-duration="500">
                                <h3>日系IT企業向けの中国人材育成事業</h3>
                                <p>中国におけるソフトウェア受託開発 日本企業から受託したソフトウェア中国の関連会社と開発を行います</p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                        <div class="about-one__box" data-aos="fade-right" :data-aos-duration="500">
                            <div class="about-one__box-icon">
                                <i class="fa fa-check"></i>
                            </div>
                            <!-- /.about-one__box-icon -->
                            <div class="about-one__box-content">
                                <h3>自社製品開発及び販売</h3>
                                <p>情報サービス業務の開発、販売、運用、保守</p>
                            </div>
                            <!-- /.about-one__box-content -->
                        </div>
                        <!-- /.about-one__box -->
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
                <!-- /.about-one__content -->
            </div>
            <!-- /.col-lg-6 -->
            <div class="col-lg-6 d-flex">
                <div class="my-auto">
                    <div class="about-one__image wow slideInDown" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                        <img src="/assets/images/mocups/about-1-moc-1.png" alt="">
                    </div>
                    <!-- /.about-one__image -->
                </div>
                <!-- /.my-auto -->
            </div>
            <!-- /.col-lg-6 -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container -->
</section>
</template>

<script>
import {ref} from "vue";
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb },
  setup() {
      const HomeInfo = ref({})
      $.ajax({
        type: "GET",
        url: "/api/company.php",
        dataType: "json",
        async: false,
        success: (res) => {
            HomeInfo.value = res;
        },
     })
     return {HomeInfo}
  },
  methods: {
    formatPrice(value) {
        return (value||'1000').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
