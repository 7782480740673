<template>
  
        <section class="banner-one">
            <div class="particles-snow" id="banner-one-snow"></div>
            <!-- /#cta-one-snow.particles-snow -->

            <img src="/assets/images/shapes/footer-shape-1-1.png" class="banner-one__bg-shape-1" alt="">
            <img src="/assets/images/shapes/footer-shape-1-2.png" class="banner-one__bg-shape-2" alt="">
            <img src="/assets/images/shapes/footer-shape-1-3.png" class="banner-one__bg-shape-3" alt="">
            <img src="/assets/images/shapes/footer-shape-1-4.png" class="banner-one__bg-shape-4" alt="">
            <img src="/assets/images/shapes/footer-shape-1-2.png" class="banner-one__bg-shape-5" alt="">
            <div class="container">
                <div class="banner-one__image wow slideInUp" data-wow-duration="1500ms">
                    <img src="/assets/images/mocups/banner-moc-1-1.png" class="banner-one__image-1 " alt="">
                    <img src="/assets/images/mocups/banner-moc-1-2.png" class="banner-one__image-2 float-bob-y" alt="">
                    <img src="/assets/images/mocups/banner-moc-1-3.png" class="banner-one__image-3 float-bob-x" alt="">
                    <img src="/assets/images/mocups/banner-moc-1-4.png" class="banner-one__image-4" alt="">
                    <img src="/assets/images/mocups/banner-moc-1-5.png" class="banner-one__image-5" alt="">
                </div>
                <!-- /.banner-one__image -->
                <div class="row">
                    <div class="col-lg-6">
                        <div class="banner-two__content" data-aos="fade-right" :data-aos-duration="500">
                            <h3><span>お客様の価値創造に貢献して参ります</span></h3>
                              <p>常に新しい技術に挑戦しマスターする事で、
                              <br>新規事業を開拓しお客様の期待に応えられる様な課題解決能力を身につけ、
                              <br>新たな価値を創造してお客様に提供して参ります。 
                            </p>
                            <div class="banner-two__btn-block">
                                <router-link to="/recruit" class="thm-btn">Join Us Now</router-link>
                                <!-- /.thm-btn -->
                                <router-link to="/service" class="thm-btn">Our Services</router-link>
                                <!-- /.thm-btn -->
                            </div>
                            <!-- /.banner-two__btn-block -->
                        </div>
                        <!-- /.banner-two__content -->
                    </div>
                    <!-- /.col-lg-6 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.banner-one -->

        <section class="service-three">

            <div class="section__bubble-1"></div>
            <!-- /.section__bubble-1 -->
            <div class="section__bubble-2"></div>
            <!-- /.section__bubble-2 -->
            <div class="section__bubble-3"></div>
            <!-- /.section__bubble-3 -->
            <div class="section__bubble-4"></div>
            <!-- /.section__bubble-4 -->
            <div class="section__bubble-5"></div>
            <!-- /.section__bubble-5 -->
            <div class="section__bubble-6"></div>
            <!-- /.section__bubble-6 -->
            <div class="section__bubble-7"></div>
            <!-- /.section__bubble-7 -->
            <div class="section__bubble-8"></div>
            <!-- /.section__bubble-8 -->


            <div class="container">
                <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                    <p><span>Business Contents</span></p>
                    <h3>主な事業内容</h3>
                </div>
                <!-- /.block-title text-center -->
                <div class="row high-gutters">
                    <div class="col-lg-4">
                        <div class="service-three__single wow flipInY" data-wow-delay="0ms" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                            <div class="service-three__icon">
                                <div class="service-three__single-circle"></div>
                                <!-- /.service-three__single-circle -->
                                <img src="/assets/images/shapes/service-3-i-1.png" alt="">
                            </div>
                            <!-- /.service-three__icon -->
                            <h3><router-link to="/serviceDetail/Development">オフショア開発</router-link></h3>
                            <p>お客様のご要望を基づくオフショア開発。お客様のご要望によるラボ契約での運用監視サービス。 中国進出の日本企業向けのシステム開発・保守サービス。</p>
                            <!-- /.thm-btn -->
                        </div>
                        <!-- /.service-three__single -->
                    </div>
                    <!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="service-three__single wow flipInY" data-wow-delay="300ms" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                            <div class="service-three__icon">
                                <div class="service-three__single-circle"></div>
                                <!-- /.service-three__single-circle -->
                                <img src="/assets/images/shapes/service-3-i-2.png" alt="">
                            </div>
                            <!-- /.service-three__icon -->
                            <h3><router-link to="/serviceDetail/CRM">開発・保守</router-link></h3>
                            <p>お客様のご要望に応じた「OS、DB、言語、ツール、フレームワーク」によるソフトウエア開発・保守サービス。 汎用コンピュータの開発、コンバージョン。</p>
                            <!-- /.thm-btn -->
                        </div>
                        <!-- /.service-three__single -->
                    </div>
                    <!-- /.col-lg-4 -->
                    <div class="col-lg-4">
                        <div class="service-three__single wow flipInY" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <div class="service-three__icon" data-aos="fade-right" :data-aos-duration="500">
                                <div class="service-three__single-circle"></div>
                                <!-- /.service-three__single-circle -->
                                <img src="/assets/images/shapes/service-3-i-3.png" alt="">
                            </div>
                            <!-- /.service-three__icon -->
                            <h3 data-aos="fade-right" :data-aos-duration="500"><router-link to="/serviceDetail/Embedded">iOS・Android</router-link></h3>
                            <p data-aos="fade-right" :data-aos-duration="500">ブロックチェーン開発、CRMシステム開発、組込み系システム開発、コンサルティング、データ‐マイニング、OCR開発、ウェブ‐クローラーなど。</p>
                            <!-- /.thm-btn -->

                        </div>
                        <!-- /.service-three__single -->
                    </div>
                    <!-- /.col-lg-4 -->
                </div>
                <!-- /.row high-gutters -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.service-three -->

        <section class="about-two">
            <img src="/assets/images/shapes/about-2-bg-1-1.png" class="about-two__bg-shape-1" alt="">
            <div class="container">
                <img src="/assets/images/shapes/about-2-bg-1-2.png" class="about-two__bg-shape-2" alt="">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-two__images wow fadeInLeft" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
                            <img src="/assets/images/mocups/about-2-moc-1.png" class="about-two__image-1 float-bob-y" alt="">
                            <img src="/assets/images/mocups/about-2-moc-2.png" class="about-two__image-2" alt="">
                            <img src="/assets/images/mocups/about-2-moc-3.png" class="about-two__image-3 float-bob-y" alt="">
                        </div>
                        <!-- /.about-two__images -->
                    </div>
                    <!-- /.col-lg-6 -->
                    <div class="col-lg-6">
                        <div class="about-two__content">
                            <div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
                                <p class="color-2"><span>About Company</span></p>
                                <h3>{{HomeInfo.CompanyName}}について</h3>
                            </div>
                            <!-- /.block-title text-left -->
                            <p data-aos="fade-right" :data-aos-duration="500">人材とサービス精神でお客様と強固且長期安定的なパートナー関係を構築して参ります。 弊社は「人材・信頼・共生・効率・創造」を礎に、未来に向けて優れたソリューションを持ち、 コンサルティングから運用・アウトソーシングまで、一流のサービスを提供する国際的情報サービスグループ企業を目指して参ります。</p>

                            <div class="about-two__counter-wrap">
                                <div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-two__count"><span class="counter">100</span>
                                        <!-- /.counter --><b>+</b></div>
                                    <!-- /.about-two__count -->
                                    <h3>Projects</h3>
                                </div>
                                <!-- /.about-two__counter -->
                                <div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-two__count"><span class="counter">10</span>
                                        <!-- /.counter --><b>+</b></div>
                                    <!-- /.about-two__count -->
                                    <h3>Clients</h3>
                                </div>
                                <!-- /.about-two__counter -->
                                <div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-two__count"><span class="counter">10</span>
                                        <!-- /.counter --><b>+</b></div>
                                    <!-- /.about-two__count -->
                                    <h3>Advisors</h3>
                                </div>
                                <!-- /.about-two__counter -->
                            </div>
                            <!-- /.about-two__counter-wrap -->
                        </div>
                        <!-- /.about-two__content -->
                    </div>
                    <!-- /.col-lg-6 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.about-two -->

        <section class="cta-one cta-one__home-one">
            <div class="particles-snow" id="cta-one-snow"></div>
            <!-- /#cta-one-snow.particles-snow -->

            <img src="/assets/images/shapes/footer-shape-1-1.png" class="cta-one__bg-shape-1" alt="" data-aos="fade-right" :data-aos-duration="500">
            <img src="/assets/images/shapes/footer-shape-1-2.png" class="cta-one__bg-shape-2" alt="" data-aos="fade-right" :data-aos-duration="500">
            <img src="/assets/images/shapes/footer-shape-1-3.png" class="cta-one__bg-shape-3" alt="" data-aos="fade-right" :data-aos-duration="500">
            <img src="/assets/images/shapes/footer-shape-1-4.png" class="cta-one__bg-shape-4" alt="" data-aos="fade-right" :data-aos-duration="500">

            <div class="container" data-aos="fade-right" :data-aos-duration="500">
                <h3>社員と会社が共にある。<br>活躍できる舞台がこにある。</h3>
                <router-link to="/recruit" class="thm-btn cta-one__btn"><span>Join Us Now</span></router-link>
                <!-- /.thm-btn cta-one__btn -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.cta-one -->

        <section class="service-one">
            <div class="container">
                <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                    <p><span>Our Services</span></p>
                    <h3>サービス</h3>
                </div>
                <!-- /.block-title text-center -->
                <div class="row high-gutters">
                    <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="1500ms">
                        <div class="service-one__single">
                            <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                                <div class="service-one__icon-inner">
                                    <img src="/assets/images/shapes/service-i-1.png" alt="">
                                </div>
                                <!-- /.service-one__icon-inner -->
                            </div>
                            <!-- /.service-one__icon -->
                            <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                                <h3><router-link to="/serviceDetail/Development">システム開発</router-link></h3>
                                <p>金融・医療・物流</p>
                            </div>
                            <!-- /.service-one__content -->
                        </div>
                        <!-- /.service-one__single -->
                    </div>
                    <!-- /.col-lg-6 col-md-12 -->
                    <div class="col-lg-6 col-md-12 wow fadeInRight" data-wow-duration="1500ms">
                        <div class="service-one__single">
                            <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                                <div class="service-one__icon-inner">
                                    <img src="/assets/images/shapes/service-i-2.png" alt="">
                                </div>
                                <!-- /.service-one__icon-inner -->
                            </div>
                            <!-- /.service-one__icon -->
                            <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                                <h3><router-link to="/serviceDetail/CRM">CRMシステム</router-link></h3>
                                <p>開発製造、既製品のカスタマイズ</p>
                            </div>
                            <!-- /.service-one__content -->
                        </div>
                        <!-- /.service-one__single -->
                    </div>
                    <!-- /.col-lg-6 col-md-12 -->
                    <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-duration="1500ms">
                        <div class="service-one__single">
                            <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                                <div class="service-one__icon-inner">
                                    <img src="/assets/images/shapes/service-i-3.png" alt="">
                                </div>
                                <!-- /.service-one__icon-inner -->
                            </div>
                            <!-- /.service-one__icon -->
                            <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                                <h3><router-link to="/serviceDetail/Smartphone">スマホ・タブレット</router-link></h3>
                                <p>スマートデバイス活用 アプリ・システム開発
                                </p>
                            </div>
                            <!-- /.service-one__content -->
                        </div>
                        <!-- /.service-one__single -->
                    </div>
                    <!-- /.col-lg-6 col-md-12 -->
                    <div class="col-lg-6 col-md-12 wow fadeInRight" data-wow-duration="1500ms">
                        <div class="service-one__single">
                            <div class="service-one__icon" data-aos="fade-right" :data-aos-duration="500">
                                <div class="service-one__icon-inner">
                                    <img src="/assets/images/shapes/service-i-4.png" alt="">
                                </div>
                                <!-- /.service-one__icon-inner -->
                            </div>
                            <!-- /.service-one__icon -->
                            <div class="service-one__content" data-aos="fade-right" :data-aos-duration="500">
                                <h3><router-link to="/serviceDetail/AI">人工知能</router-link></h3>
                                <p>AIテクノロジーへの取組</p>
                            </div>
                            <!-- /.service-one__content -->
                        </div>
                        <!-- /.service-one__single -->
                    </div>
                    <!-- /.col-lg-6 col-md-12 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.service-one -->

        <section class="about-three">
            <img src="/assets/images/shapes/about-3-bg-1-1.png" class="about-three__bg-image-1" alt="">

            <div class="section__bubble-1" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-1 -->
            <div class="section__bubble-2" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-2 -->
            <div class="section__bubble-3" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-3 -->
            <div class="section__bubble-4" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-4 -->
            <div class="section__bubble-5" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-5 -->
            <div class="section__bubble-6" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-6 -->
            <div class="section__bubble-7" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-7 -->
            <div class="section__bubble-8" data-aos="fade-right" :data-aos-duration="500"></div>
            <!-- /.section__bubble-8 -->

            <div class="container">
                <img src="/assets/images/shapes/about-3-bg-1-2.png" class="about-three__bg-image-2" alt="">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-three__content">
                            <div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
                                <p class="color-2"><span>AUM Advantage</span></p>
                                <h3>AUMの強み</h3>
                            </div>
                            <!-- /.block-title text-center -->
                            <p data-aos="fade-right" :data-aos-duration="500">幅広いシステムの課題を解決する、開発実績と中国の最新技術。<br/>日本での長年にわたる豊富なシステム開発の技術に加えて、中国でシステム開発のトレンドや最新技術を調査し、実際の開発に取り入れることができます。<br/>

                            </p>
                            <div class="about-three__progress-wrap">
                                <div class="about-three__progress" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-three__progress-top">
                                        <h3>システム支援</h3>
                                        <p><span class="counter">60</span>
                                            <!-- /.counter -->%</p>
                                    </div>
                                    <!-- /.about-three__progress-top -->
                                    <div class="about-three__progress-bar">
                                        <span style="width: 60%" class="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                    <!-- /.about-three__progress-bar -->
                                </div>
                                <!-- /.about-three__progress -->
                                <div class="about-three__progress" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-three__progress-top">
                                        <h3>専門分野と提供サービスの拡充</h3>
                                        <p><span class="counter">80</span>
                                            <!-- /.counter -->%</p>
                                    </div>
                                    <!-- /.about-three__progress-top -->
                                    <div class="about-three__progress-bar">
                                        <span style="width: 80%" class="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                    <!-- /.about-three__progress-bar -->
                                </div>
                                <!-- /.about-three__progress -->
                                <div class="about-three__progress" data-aos="fade-right" :data-aos-duration="500">
                                    <div class="about-three__progress-top">
                                        <h3>技術・品質重視</h3>
                                        <p><span class="counter">90</span>
                                            <!-- /.counter -->%</p>
                                    </div>
                                    <!-- /.about-three__progress-top -->
                                    <div class="about-three__progress-bar">
                                        <span style="width: 90%" class="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                    <!-- /.about-three__progress-bar -->
                                </div>
                                <!-- /.about-three__progress -->
                            </div>
                            <!-- /.about-three__progress-wrap -->
                        </div>
                        <!-- /.about-three__content -->
                    </div>
                    <!-- /.col-lg-6 -->
                    <div class="col-lg-6" data-aos="fade-right" :data-aos-duration="500">
                        <div class="about-three__images wow fadeInRight" data-wow-duration="1500ms">
                            <img src="/assets/images/mocups/about-3-moc-1-1.png" class="about-three__image-1 float-bob-y" alt="">
                            <img src="/assets/images/mocups/about-3-moc-1-2.png" class="about-three__image-2 float-bob-y" alt="">
                            <img src="/assets/images/mocups/about-3-moc-1-3.png" class="float-bob-x about-three__image-3" alt="">
                        </div>
                        <!-- /.about-three__images -->
                    </div>
                    <!-- /.col-lg-6 -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.about-three -->

        <section class="pricing-one">
            <div class="container">
                <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                    <p class="color-2"><span>News</span></p>
                    <h3>お知らせ</h3>
                </div>
                <!-- /.block-title text-center -->
                <div class="row high-gutters">
                    <div class="item col-lg-4" style="margin-bottom:30px;min-height:370px" v-for="(item, index) in newsList" :key="index">
                        <div class="blog-one__single">
                            <div class="blog-one__image" style="height: 200px;overflow: hidden;">
                                 <img :src="item.img" style="height: 200px;">
                                 <router-link :to="`/news/${item.id}`"><i class="fal fa-plus"></i></router-link>
                            </div>
                            <!-- /.blog-one__image -->
                            <div class="blog-one__content">
                                <div class="blog-one__meta">
                                    <router-link :to="`/news/${item.id}`">
                                        {{ item.time }}
                                    </router-link>
                                </div>
                                <!-- /.blog-one__meta -->
                                <h3 style="font-size:16px;word-break:break-all;word-wrap:break-word;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"> <router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></h3>
                                <router-link :to="`/news/${item.id}`" class="thm-btn blog-one__btn"><span>Read More</span></router-link>
                                <!-- /.thm-btn blog-one__btn -->
                            </div>
                            <!-- /.blog-one__content -->
                        </div>
                        <!-- /.blog-one__single -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container -->
        </section>
        <!-- /.pricing-one -->
</template>


<script>
import { onMounted, ref } from "vue";
export default {
  name: "Home",
  setup() {
    const initSlick = () => {
      
    };
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      initSlick();
      getNewsList();
    });
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>
