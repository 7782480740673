<template>
<!-- Start aum breadcrumb section -->
<BreadCrumb ImageUrl="/assets/images/shapes/header-bg-6.png" PageName="サービス"/>
<!-- End aum breadcrumb section -->
<section class="container" style="margin-top:50px;margin-bottom:50px">
    <div class="block-title text-center">
        <p class="color-2"><span>Service</span></p>
    </div>
    <!-- /.block-title text-center -->

    <div class="row high-gutters">
        <div class="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInLeft;" 
             data-aos="fade-right" :data-aos-duration="500" v-for="(item,index) in list" v-bind:key="index">
            <div class="pricing-one__single">
               <a :href="'/ServiceDetail/'+item.link">
                  <div class="pricing-one__icon">
                      <img :src="item.img" alt="" style="width:100%">
                  </div>
                  <ul class="pricing-one__list list-unstyled">
                      <li>{{item.title}}</li>
                      <li>{{item.subtitle}}</li>
                  </ul>
               </a>
            </div>
        </div>
    </div>
    <!-- /.row -->
</section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute } from "vue-router";
export default {
  name: "Service",
  components:{ BreadCrumb },
  setup() {
    const route = useRoute();
    const list = [
        {
          link:'CRM',
          img: '/assets/images/service/4.png',
          title: 'CRMシステム',
          subtitle: '顧客管理・商談管理、マルチチャネル連携など'
        },
        {
          link:'Development',
          img: '/assets/images/service/1.png',
          title: 'システム開発',
          subtitle: '金融・医療・物流'
        },
        {
          link:'AI',
          img: '/assets/images/service/5.png',
          title: '人工知能',
          subtitle: 'AIテクノロジーへの取組'
        },
        {
          link:'Embedded',
          img: '/assets/images/service/2.png',
          title: '組込開発',
          subtitle: '通信・製品・車載'
        },
        {
          link:'DataMigration',
          img: '/assets/images/service/3.png',
          title: 'データ移行',
          subtitle: 'システム・各種デバイス'
        },
        {
          link:'BigData',
          img: '/assets/images/service/6.png',
          title: 'ビッグデータ',
          subtitle: 'ビッグデータの効果的活用'
        }
    ]
    return {list}
  }
}
</script>
